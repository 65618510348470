import React, { useEffect, useState } from 'react'
import { Heading, Link, Box, Image, Card, Grid, Flex } from 'theme-ui'
import Compute from 'components/compute'
import PhotoBanner from 'components/photo-banner'
import Layout from 'components/layout'
import SEO from 'components/seo'

const JSON_SAMPLES =
  'https://spreadsheets.google.com/feeds/cells/1-32-fIHRKMgVKgCtgzlGwdi9xIWvTPAPwEQkEFJlmvg/1/public/full?alt=json'

export default function Samples() {
  const [data, setData] = useState<any>([])
  useEffect(() => {
    fetch(JSON_SAMPLES)
      .then(res => res.json())
      .then(
        (result: any) => {
          const { gs$colCount, gs$rowCount } = result.feed || {}
          const { $t: col = 0 } = gs$colCount || {}
          const { $t: row = 0 } = gs$rowCount || {}
          const entry = result.feed.entry
          let flag = 0
          let obj: any = {}
          const d = []
          for (let x = 0; x < entry.length; x++) {
            flag = flag + 1
            const { content } = entry[x] || {}
            const { $t: value } = content
            if (flag === 1) {
              obj.name = value
            }
            if (flag === 2) {
              obj.img = value
            }
            if (flag === 3) {
              obj.size = value
            }
            if (flag === 4) {
              obj.link = value
            }
            if (flag === Number(col)) {
              d.push(obj)
              flag = 0
              obj = {}
            }
          }
          setData(d)
        },
        error => {}
      )
  }, [])
  return (
    <Layout>
      <SEO
        title="Samples"
        description="Sticker samples from Printado Printing Services"
      />
      <Box sx={{ maxWidth: '1024px', margin: '0 auto' }}>
        <Flex
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
          p={2}
        >
          <Heading variant="headerText">Samples</Heading>
        </Flex>
        <Grid gap={0} columns={[1, 2, 2]}>
          {data.map((sample: any, key: any) => {
            return (
              <Box key={key} sx={{ width: '100%' }}>
                <Card
                  sx={{
                    maxWidth: 500,
                    margin: '0 auto',
                  }}
                  variant="cards.primary"
                >
                  <Image src={`${sample.img}`} />
                  <div>
                    <Link
                      target="_blank"
                      href={sample.link}
                      color="text"
                      sx={{
                        fontSize: ['20px', '20px', '35px'],
                        textDecoration: 'none',
                        fontWeight: 'bold',
                      }}
                      variant="link"
                      title={`${sample.name} | Printado Printing Services`}
                    >
                      {sample.name}
                    </Link>
                  </div>
                  <div>{sample.size}</div>
                </Card>
              </Box>
            )
          })}
        </Grid>
      </Box>
    </Layout>
  )
}
